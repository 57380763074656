import appConfig from "../config";
import { useParams } from "react-router-dom";
import { doApiCall } from "../api";
import { useState } from "react";

// @see https://dev.to/awscommunity-asean/aws-cognito-hosted-ui-34ep

const LoginButton = () => {
  const [refreshSuccess, setRefreshSuccess] = useState();
  const { authDomain, ClientId, apiBaseUrl } = appConfig;

  const apiCallback = `${apiBaseUrl}/oauth2/callback`;
  const url = new URL(`${authDomain}/login`);
  url.searchParams.append('client_id', ClientId);
  url.searchParams.append('response_type', 'code');
  url.searchParams.append('scope', 'email openid phone profile');
  url.searchParams.append('redirect_uri', apiCallback);
  url.searchParams.append('state', encodeURI(window.location.href));

  const tryRefresh = async () => {
    try {
      await doApiCall('/oauth2/refresh');
      setRefreshSuccess(true);
    } catch (e) {
      setRefreshSuccess(false);
    }
  };

  return (
    <>
      <div className="btn-group" role="group" aria-label="Basic example">
        <a className="btn btn-primary" href={url.href} role="button">Please login</a>
        <button className="btn btn-secondary" onClick={tryRefresh}>Try token refresh</button>
      </div>
      <p>
        {refreshSuccess != null && 'Refresh result: ' + (refreshSuccess ? '✅' : '❌')}
      </p>
    </>
  );
};

export default LoginButton;
