import axios from "axios";
import appConfig from "./config";

export const doApiCall = (path, venueSlug = null) => {
  const config = {
    withCredentials: true,
  };

  if (venueSlug) {
    config.headers = {
      'X-Venue-Id': venueSlug,
    };
  }

  const { apiBaseUrl } = appConfig;

  return axios.get(`${apiBaseUrl}${path}`, config);
};
