import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter, Link,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import appConfig from "./config";
import AppIframe from "./AppIframe";

const { apiBaseUrl } = appConfig;

const client = new ApolloClient({
  uri: `${apiBaseUrl}/api/venue/graphql`,
  mode: 'cors',
  credentials: 'include',
  cache: new InMemoryCache(),
});

const router = createBrowserRouter([
  {
    path: '/iframe',
    element: <AppIframe />,
  },
  {
    path: "/",
    element: (
      <>
        <h2>VMA root</h2>
        <p>
          Pick a random venue: <br/>
          <ul>
            <li><Link to="/test">Test</Link></li>
            <li><Link to="/beachclubbar">Beachclub Bar</Link></li>
          </ul>
        </p>
      </>
    ),
  },
  {
    path: "/:venueSlug",
    element: <App />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
