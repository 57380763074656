import './App.css';

function AppIframe() {
  return (
    <div className="App">
      <h1>Widget test</h1>
      <iframe src="https://test.star.dev.constell.com" width="500px" height="400px"></iframe>
    </div>
  );
}

export default AppIframe;
