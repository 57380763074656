import './App.css';
import LoginButton from "./LoginButton";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";

const INTROSPECTION_QUERY = gql`
  query IntrospectionQuery {
      __schema {
          queryType { name }
          mutationType { name }
          subscriptionType { name }
      }
  }
`;

function App2() {
  const { venueSlug } = useParams();
  const { data, loading, error } = useQuery(INTROSPECTION_QUERY, {
    context: {
      headers: {
        'X-Venue-Id': venueSlug,
      },
    },
  });

  if (loading) return "Loading...";
  if (error && error.networkError) {
    return (
      <>
        <p>👮 Seems like you're not logged in or not allowed to access this venue. 👮</p>
        <LoginButton />
      </>
    );
  }

  return (
    <div className="App">
      <h1>VMA App for {venueSlug}</h1>

      <p>We did a request to the backend and received something from there:</p>
      {!error && data && (
        <>
          <p>Successful call! 🚀</p>
          <pre>
            {JSON.stringify(data, null, 2)}
          </pre>
        </>
      )}

      {error && (
        <>
          <p>Error in GraphQL call 🔥 (but the request technically went through OK)</p>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </>
      )}
    </div>
  );
}

export default App2;
